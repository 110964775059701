/* eslint-disable react/prop-types,react/display-name */
import React, { useState } from 'react';
import { keyframes } from '@emotion/core';
import {
	Box,
	Col,
	Heading,
	Row,
	Text,
	ThemeProvider,
} from '@fast-ai/ui-components';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import EmailIcon from '@fast-ai/dashboard-icons/src/Email';
import ArrowDownIcon from '@fast-ai/dashboard-icons/src/KeyboardArrowDown';
import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';
import ExternalLink from '@fast-ai/ui/ExternalLink';
import { useForm } from 'react-form';

import { createContrastTheme } from '../contrastTheme';
import { DefaultLayout } from '../layout';
import { TextField } from '../forms';
import { ButtonWithIcon, CompaniesBrands, Contacts, Section, Seo } from '../ui';
import { sendEmail } from '../api';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageScheduleDemo';
import ScheduleMeetingSection from '../ui/ScheduleMeetingSection';

const arrowRightAnimation = keyframes`
0% {
	transform: rotateZ(90deg) translate(-50%, 0%);
}
100% {
	transform: rotateZ(90deg) translate(-50%, 20%);
}
`;

const arrowLeftAnimation = keyframes`
0% {
	transform: rotateZ(-90deg) translate(50%, 0%);
}
100% {
	transform: rotateZ(-90deg) translate(50%, 20%);
}
`;

const Separator = () => {
	const { inViewport, ref } = useJustIsInViewport({ threshold: 1 });
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				mx: 'auto',
				my: [4, 4, 0],
				flexDirection: ['row', 'row', 'column'],
				height: ['auto', 'auto', '100%'],
				position: 'relative',
				'.arrow': {
					color: 'secondary',
					position: 'absolute',
					display: ['none', 'none', 'block'],
					top: '50%',
					animationDuration: '0.4s',
					animationIterationCount: '10',
					animationFillMode: 'both',
					animationTimingFunction: 'linear',
					animationDirection: 'alternate',
				},
				'&:hover': {
					'.arrow': {
						animationIterationCount: 'infinite',
					},
				},
			}}
		>
			<Box
				sx={{
					display: 'inline-block',
					height: [0, 0, '50vh'],
					width: ['50vw', '50vw', 0],
					borderLeftStyle: 'dashed',
					borderLeftWidth: '8px',
					borderLeftColor: 'secondary',
					borderBottomStyle: 'dashed',
					borderBottomWidth: '8px',
					borderBottomColor: 'secondary',
				}}
			/>
			<Box
				ref={ref}
				as={ArrowDownIcon}
				size={80}
				className="arrow"
				sx={{
					left: 0,
					transform: 'rotateZ(90deg) translate(-50%, 0%)',
					animationName: inViewport && arrowRightAnimation,
				}}
			/>

			<Box
				as={ArrowDownIcon}
				size={80}
				className="arrow"
				sx={{
					right: 0,
					transform: 'rotateZ(-90deg) translate(50%, 0%)',
					animationName: inViewport && arrowLeftAnimation,
				}}
			/>
		</Box>
	);
};

const Card = ({ sx, ...rest }) => (
	<Box as="article" sx={{ py: 4, ...sx }} {...rest} />
);
const defaultValues = {
	name: '',
	email: '',
	phoneNumber: '',
	message: '',
};

const phoneNumberRegExp =
	/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const validatePhone = x => phoneNumberRegExp.exec(x);

const validateEmail = email =>
	String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
const Status = {
	EMAIL_SUBMITTED: 'EMAIL_SUBMITTED',
	IN_PROGRESS_OF_FILL_IN: 'IN_PROGRESS_OF_FILL_IN',
};
const FormSection = () => {
	const [status, setStatus] = useState(Status.IN_PROGRESS_OF_FILL_IN);
	const [errorMessage, setErrorMessage] = useState('');

	const translate = useTranslateMessage();
	const {
		Form,
		meta: { isSubmitting, canSubmit },
		reset,
	} = useForm({
		defaultValues,
		onSubmit: async values => {
			try {
				setErrorMessage('');
				await sendEmail(values);

				setStatus(Status.EMAIL_SUBMITTED);
				reset();
			} catch (error) {
				setErrorMessage(translate(m.formInvalidMailOrPhone));
			}
		},
	});
	if (status === Status.EMAIL_SUBMITTED) {
		return (
			<Card>
				<Heading>
					<RichFormattedMessage {...m.emailSubmittedHeading} />
				</Heading>
				<Heading as="h2" variant="subHeading1">
					<RichFormattedMessage {...m.emailSubmittedSubheading} />
				</Heading>
				<Paragraph>
					<RichFormattedMessage {...m.messageAfterSuccess} />
				</Paragraph>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						mx: 'auto',
						'button + button, button + a': { ml: 2 },
					}}
				>
					<ButtonWithIcon
						label={<RichFormattedMessage {...m.newMessage} />}
						icon={<EmailIcon />}
						onClick={() => setStatus(Status.IN_PROGRESS_OF_FILL_IN)}
					/>
					<ButtonWithIcon
						as={ExternalLink}
						variant="outline"
						to="/"
						label={<RichFormattedMessage {...m.home} />}
					/>
				</Box>
			</Card>
		);
	}
	return (
		<Form>
			<Card>
				<RichFormattedMessage
					{...m.sendAMessageHeading}
					values={{
						span: (...chunks) => <Text sx={{ mb: -2 }}>{chunks}</Text>,
						h1: (...chunks) => (
							<Heading variant="subHeading1">{chunks}</Heading>
						),
					}}
				/>

				<Box sx={{ textAlign: 'left', mb: 2 }}>
					{errorMessage && (
						<Paragraph>
							<Text as="span" sx={{ color: 'danger' }}>
								{errorMessage}
							</Text>
						</Paragraph>
					)}
					<Text>
						<RichFormattedMessage {...m.howToFillForm} />
					</Text>
					<TextField
						label={<RichFormattedMessage {...m.nameSurnameLabel} />}
						field="name"
						validate={x => (!x ? translate(m.requiredName) : false)}
					/>
					<TextField
						label={<RichFormattedMessage {...m.emailLabel} />}
						field="email"
						validate={x => {
							if (x) {
								return !validateEmail(x) && translate(m.invalidEmail);
							}
							return false;
						}}
					/>

					<TextField
						label={<RichFormattedMessage {...m.phoneLabel} />}
						field="phoneNumber"
						validate={x => {
							if (x) {
								return !validatePhone(x) && translate(m.invalidPhone);
							}
							return false;
						}}
					/>
					<TextField
						inputProps={{ as: 'textarea' }}
						label={<RichFormattedMessage {...m.messageLabel} />}
						field="message"
					/>
				</Box>

				<Box>
					<ButtonWithIcon
						disabled={!canSubmit || isSubmitting}
						sx={{ margin: 'auto' }}
						label={<RichFormattedMessage {...m.sendMessage} />}
						icon={<EmailIcon />}
					/>
				</Box>
			</Card>
		</Form>
	);
};

const ScheduleDemoPage = props => {
	const translate = useTranslateMessage();
	return (
		<DefaultLayout {...props}>
			<Seo title={translate(m.title)} />
			<Section>
				<Row>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.heading} />
						</Heading>
					</Col>
				</Row>

				<Row sx={{ flexWrap: 'wrap' }}>
					<ThemeProvider theme={createContrastTheme}>
						<Col
							span={[12, 12, 5]}
							sx={{ backgroundColor: 'background', borderRadius: 'basic' }}
						>
							<ScheduleMeetingSection {...props} />
						</Col>
					</ThemeProvider>
					<Col span={[12, 12, 2]}>
						<Separator />
					</Col>
					<Col
						sx={{
							backgroundColor: 'backgroundPrimary',
							borderStyle: 'solid',
							borderWidth: '4px',
							borderColor: 'primary',
							borderRadius: 'basic',
						}}
						span={[12, 12, 5]}
					>
						<FormSection {...props} />
					</Col>
				</Row>
			</Section>

			<Section variant="stripesSecondary">
				<Row sx={{ flexWrap: 'wrap' }}>
					<Col span={12}>
						<Heading id="contacts" sx={{ pt: [0], mb: [0, 0, 0] }}>
							<RichFormattedMessage {...m.contactsHeading} />
						</Heading>
					</Col>
				</Row>
				<Contacts />
			</Section>

			<Section>
				<CompaniesBrands />
			</Section>
		</DefaultLayout>
	);
};

export default ScheduleDemoPage;
