/* eslint-disable max-len */
import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<React.Fragment>
		<g
			fill="none"
			stroke="currentcolor"
			transform="translate(-18.149 -26.5736) scale(2.12685)"
		>
			<circle cx="16" cy="20" r="3" />
			<path d="M19.6 26c-.348.208-.723.387-1.087.528M17.414 26.849c-.35.072-.706.117-1.066.134M15.101 26.932c-3.418-.45-6.093-3.407-6.093-6.94 0-3.84 3.16-7 7-7 .114 0 .228.002.342.008 3.769.274 6.707 3.482 6.65 7.26V21c0 1.097-.903 2-2 2s-2-.903-2-2v-1" />
		</g>
	</React.Fragment>,
	32,
	'Email'
);
