export const getConfigVariable = (variable, defaultValue) => {
	if (typeof window === 'undefined') {
		return defaultValue;
	}

	const runtimeValue = window?.appConfig?.[variable];

	return typeof runtimeValue === 'undefined' ? defaultValue : runtimeValue;
};

export const getApiUrl = () =>
	`${getConfigVariable('API_URL', 'https://zoe.lundegaard.ai')}/zoe-portal/api`;
