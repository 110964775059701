/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-theme-fast-ai-sidebar';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import SendIcon from '@fast-ai/dashboard-icons/src/Programming1Asset69';
import {
	Box,
	// Col,
	Heading,
	// Row,
	Text,
	// ThemeProvider,
} from '@fast-ai/ui-components';

import { GatsbyDashedCircleImage, SalesCalendlyButton } from '../ui';
import { RichFormattedMessage } from '../intl';
import m from '../intl/messages/pageScheduleDemo';

const Card = ({ sx, ...rest }) => (
	<Box as="article" sx={{ py: 4, ...sx }} {...rest} />
);
const ScheduleMeetingSection = () => {
	const data = useStaticQuery(
		graphql`
			query {
				michal: file(relativePath: { eq: "michal-krnak.jpg" }) {
					childImageSharp {
						gatsbyImageData(width: 600, quality: 70, layout: CONSTRAINED)
					}
				}
			}
		`
	);

	return (
		<Card>
			<RichFormattedMessage
				{...m.scheduleHeading}
				values={{
					span: (...chunks) => <Text sx={{ mb: -2 }}>{chunks}</Text>,
					h1: (...chunks) => <Heading variant="subHeading1">{chunks}</Heading>,
				}}
			/>
			<GatsbyDashedCircleImage
				sx={{
					margin: 'auto',
					maxWidth: '250px',
					'.gatsby-image-wrapper': { mx: 'auto' },
				}}
			>
				<GatsbyImage
					image={getImage(data.michal)}
					alt="Michal Krňák"
					title="Michal Krňák"
				/>
			</GatsbyDashedCircleImage>
			<Paragraph>
				<RichFormattedMessage {...m.scheduleDescription} />
			</Paragraph>
			<Paragraph>
				<SalesCalendlyButton
					icon={<SendIcon />}
					label={<RichFormattedMessage {...m.scheduleMeeting} />}
					sx={{ mx: 'auto' }}
				/>
			</Paragraph>
		</Card>
	);
};

export default ScheduleMeetingSection;
