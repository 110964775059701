import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.scheduleDemo.title',
		defaultMessage: 'Schedule a demo',
	},
	formInvalidSubmit: {
		id: 'page.scheduleDemo.invalidSubmit',
		defaultMessage: 'Invalid submit. Please try it again.',
	},
	formMissingName: {
		id: 'page.scheduleDemo.missingName',
		defaultMessage: 'Name is required.',
	},
	formInvalidMailOrPhone: {
		id: 'page.scheduleDemo.invalidMailOrPhone',
		defaultMessage: 'Either email or phone number is required.',
	},
	scheduleHeading: {
		id: 'page.scheduleDemo.scheduleHeading',
		defaultMessage:
			"<span>Let's start with</span><h1>Meeting Michal Krňák</h1>",
	},
	scheduleDescription: {
		id: 'page.scheduleDemo.scheduleDescription',
		defaultMessage:
			'Our great sales consultant Michal will answer all your questions.',
	},
	scheduleMeeting: {
		id: 'page.scheduleDemo.scheduleMeeting',
		defaultMessage: 'Schedule meeting',
	},
	emailSubmittedHeading: {
		id: 'page.scheduleDemo.emailSubmittedHeading',
		defaultMessage: 'Thank you for your message!',
	},
	emailSubmittedSubheading: {
		id: 'page.scheduleDemo.emailSubmittedSubheading',
		defaultMessage: 'We will reply soon.',
	},
	messageAfterSuccess: {
		id: 'page.scheduleDemo.messageAfterSuccess',
		defaultMessage: 'Do you want to send another message?',
	},
	newMessage: {
		id: 'page.scheduleDemo.newMessage',
		defaultMessage: 'New message',
	},

	home: {
		id: 'page.scheduleDemo.home',
		defaultMessage: 'Main page',
	},

	sendAMessageHeading: {
		id: 'page.scheduleDemo.sendAMessageHeading',
		defaultMessage: '<span>Or just</span><h1>Send us a Message</h1>',
	},
	howToFillForm: {
		id: 'page.scheduleDemo.howToFillForm',
		defaultMessage:
			'Please fill in your name and either email or phone number so we can answer you.',
	},

	nameSurnameLabel: {
		id: 'page.scheduleDemo.nameSurnameLabel',
		defaultMessage: 'Name and surname',
	},
	requiredName: {
		id: 'page.scheduleDemo.requiredName',
		defaultMessage: 'Name is required.',
	},
	emailLabel: {
		id: 'page.scheduleDemo.emailLabel',
		defaultMessage: 'Email',
	},
	invalidEmail: {
		id: 'page.scheduleDemo.invalidEmail',
		defaultMessage: 'Invalid email.',
	},
	phoneLabel: {
		id: 'page.scheduleDemo.phoneLabel',
		defaultMessage: 'Phone number',
	},
	invalidPhone: {
		id: 'page.scheduleDemo.invalidPhone',
		defaultMessage: 'Invalid phone number.',
	},
	messageLabel: {
		id: 'page.scheduleDemo.messageLabel',
		defaultMessage: 'Message',
	},
	sendMessage: {
		id: 'page.scheduleDemo.sendMessage',
		defaultMessage: 'Send message',
	},
	heading: {
		id: 'page.scheduleDemo.heading',
		defaultMessage:
			'Ready for Zoe<strong>.</strong>ai {br} <strong>experience</strong>?',
	},
	contactsHeading: {
		id: 'page.scheduleDemo.contactsHeading',
		defaultMessage: 'Contacts',
	},
});
