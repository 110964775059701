/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M29.7 1.87L2.71 13.24l11.36 4.27 4.27 11.36 11.36-27z" />
				<path strokeDasharray="2 2 20 2" d="M16.7 14.87l12.5-12.5" />
			</g>
		</g>
	</Fragment>,
	32,
	'Programming1Asset69'
);
