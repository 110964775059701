import { getApiUrl } from './config';

const signUpFormUrl = `${getApiUrl()}/submit-sign-up-form`;
export const sendEmail = async ({
	name: nameArg = '',
	email = '',
	phoneNumber = '',
	message,
}) => {
	const name = nameArg.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');

	const result = await fetch(signUpFormUrl, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
		},
		body: JSON.stringify({
			name,
			email,
			phoneNumber,
			message,
		}),
	});
	if (!result.ok || Number(result.status) > 300) {
		throw new Error('Invalid data.');
	}
};
