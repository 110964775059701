/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const KeyboardArrowDown = forwardRef(({ ...rest }, ref) => (
	<SvgIcon viewBox="0 0 24 24" {...rest} ref={ref}>
		<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
	</SvgIcon>
));

KeyboardArrowDown.displayName = 'KeyboardArrowDown';

export default KeyboardArrowDown;
